import * as React from 'react'
import { memo } from 'react'
const SvgChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="chevron-down"
    viewBox="0 0 7.547 8"
  >
    <path d="M4.377 1.36A4.9 4.9 0 0 1 7.547.002a20.7 20.7 0 0 0-3.774 8A19.7 19.7 0 0 0 0 0a5.15 5.15 0 0 1 3.17 1.509Z" />
  </svg>
)
const Memo = memo(SvgChevronDown)
export default Memo
