import * as React from 'react'
import { memo } from 'react'
const SvgChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="chevron-left"
    viewBox="0 0 8 7.547"
  >
    <path d="M6.64 4.377a4.9 4.9 0 0 1 1.358 3.17 20.7 20.7 0 0 0-8-3.774A19.7 19.7 0 0 0 8 0a5.15 5.15 0 0 1-1.509 3.17Z" />
  </svg>
)
const Memo = memo(SvgChevronLeft)
export default Memo
