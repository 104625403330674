import * as React from 'react'
import { memo } from 'react'
const SvgPause = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="pause"
    viewBox="0 -960 960 960"
  >
    <path d="M560-240v-480h140v480zm-300 0v-480h140v480z" />
  </svg>
)
const Memo = memo(SvgPause)
export default Memo
