import * as React from 'react'
import { memo } from 'react'
const SvgInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="instagram"
    viewBox="0 0 15.998 16"
  >
    <path d="M4.7 15.953a5.9 5.9 0 0 1-1.94-.372A4.1 4.1 0 0 1 .419 13.24a5.9 5.9 0 0 1-.372-1.94C.01 10.445 0 10.174 0 8s.01-2.443.051-3.3a5.9 5.9 0 0 1 .371-1.94 3.95 3.95 0 0 1 .923-1.416A3.9 3.9 0 0 1 2.764.418 5.9 5.9 0 0 1 4.706.047C5.558.009 5.831 0 8 0s2.444.009 3.3.05a5.9 5.9 0 0 1 1.942.372 4.1 4.1 0 0 1 2.34 2.342 5.9 5.9 0 0 1 .372 1.94C16 5.557 16 5.828 16 8s-.009 2.444-.047 3.3a5.9 5.9 0 0 1-.372 1.941 4.1 4.1 0 0 1-2.342 2.341 5.9 5.9 0 0 1-1.94.372C10.443 15.99 10.172 16 8 16s-2.445-.01-3.3-.047M4.776 1.5a4.4 4.4 0 0 0-1.484.275 2.5 2.5 0 0 0-.919.6 2.4 2.4 0 0 0-.6.919A4.4 4.4 0 0 0 1.5 4.776c-.038.84-.047 1.1-.047 3.232S1.46 10.4 1.5 11.24a4.4 4.4 0 0 0 .275 1.484 2.5 2.5 0 0 0 .6.919 2.5 2.5 0 0 0 .92.6 4.4 4.4 0 0 0 1.484.275c.841.038 1.1.047 3.232.047s2.388-.009 3.232-.047a4.4 4.4 0 0 0 1.484-.275 2.66 2.66 0 0 0 1.52-1.519 4.4 4.4 0 0 0 .275-1.484c.038-.844.047-1.094.047-3.229s-.009-2.388-.047-3.232a4.4 4.4 0 0 0-.275-1.484 2.5 2.5 0 0 0-.6-.919 2.4 2.4 0 0 0-.919-.6A4.4 4.4 0 0 0 11.24 1.5c-.84-.037-1.094-.047-3.232-.047s-2.388.01-3.232.047M3.893 8A4.11 4.11 0 1 1 8 12.112 4.11 4.11 0 0 1 3.893 8m1.443 0A2.666 2.666 0 1 0 8 5.335 2.666 2.666 0 0 0 5.336 8m5.979-4.272a.96.96 0 1 1 .96.959.96.96 0 0 1-.96-.958Z" />
  </svg>
)
const Memo = memo(SvgInstagram)
export default Memo
