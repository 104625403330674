import * as React from 'react'
import { memo } from 'react'
const SvgHotel = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="hotel"
    viewBox="0 0 17.742 16"
  >
    <path d="M8.206 16v-.642h9.536V16Zm.917-2.017a3.71 3.71 0 0 1 1.02-2.613 3.7 3.7 0 0 1 2.51-1.215v-.321a.32.32 0 0 1 .092-.218.3.3 0 0 1 .229-.1.3.3 0 0 1 .218.1.3.3 0 0 1 .1.218v.321a3.7 3.7 0 0 1 2.51 1.215 3.71 3.71 0 0 1 1.02 2.613ZM0 7.656h2.476V.963H0Zm3.117 0H5.41L11.3 5.5a.95.95 0 0 0 .493-.378 1.05 1.05 0 0 0 .172-.585v-.023a.6.6 0 0 0-.183-.436.6.6 0 0 0-.436-.183H9.49l-2.613.917-.275-.642 2.842-.917h5.547a1.5 1.5 0 0 0 .986-.367 1.15 1.15 0 0 0 .436-.917L10.269 0 3.117 2.109Z" />
  </svg>
)
const Memo = memo(SvgHotel)
export default Memo
