import * as React from 'react'
import { memo } from 'react'
const SvgBed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="bed"
    viewBox="0 0 21.228 16"
  >
    <path d="M2.218 16h-.634l-.475-3.168H0V8.238a2.3 2.3 0 0 1 .693-1.683 2.3 2.3 0 0 1 1.683-.693h.792V2.377A2.3 2.3 0 0 1 3.861.694 2.3 2.3 0 0 1 5.544.001h10.139a2.3 2.3 0 0 1 1.683.693 2.3 2.3 0 0 1 .693 1.683v3.485h.792a2.3 2.3 0 0 1 1.683.693 2.3 2.3 0 0 1 .693 1.683v4.594h-1.109L19.644 16h-.634l-.475-3.168H2.693Zm8.95-10.139h5.782V2.376a1.23 1.23 0 0 0-.356-.911 1.23 1.23 0 0 0-.911-.357h-4.515Zm-6.891 0h5.782V1.108H5.544a1.23 1.23 0 0 0-.911.357 1.23 1.23 0 0 0-.356.911Z" />
  </svg>
)
const Memo = memo(SvgBed)
export default Memo
