import * as React from 'react'
import { memo } from 'react'
const SvgArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="arrow-left"
    viewBox="0 0 17.818 16"
  >
    <path d="M5.906 5.11A17 17 0 0 1 1.4 7.557L0 8.023l1.386.495a20.5 20.5 0 0 1 5 2.691A8.88 8.88 0 0 1 10.035 16l.992-.221a9.86 9.86 0 0 0-4.035-5.387 21 21 0 0 0-3-1.846h13.83V7.531H3.978a19 19 0 0 0 2.539-1.609A13.35 13.35 0 0 0 11 .378L10.056 0a12.34 12.34 0 0 1-4.151 5.11Z" />
  </svg>
)
const Memo = memo(SvgArrowLeft)
export default Memo
