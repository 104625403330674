import * as React from 'react'
import { memo } from 'react'
const SvgFacebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="facebook"
    viewBox="0 0 8 16"
  >
    <path d="M5 5.5v-2a1 1 0 0 1 1-1h1V0H5a3 3 0 0 0-3 3v2.5H0V8h2v8h3V8h2l1-2.5Z" />
  </svg>
)
const Memo = memo(SvgFacebook)
export default Memo
