import * as React from 'react'
import { memo } from 'react'
const SvgRestaurant = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="restaurant"
    viewBox="0 0 10.61 16"
  >
    <path d="M2.31 16V8.3a2.57 2.57 0 0 1-1.636-.824A2.5 2.5 0 0 1 0 5.69V0h.6v5.69h1.711V0h.6v5.69h1.711V0h.6v5.69a2.5 2.5 0 0 1-.674 1.786 2.57 2.57 0 0 1-1.636.824V16Zm7.7 0V9.155H8.042V3.722a4.35 4.35 0 0 1 .674-2.385A2.79 2.79 0 0 1 10.609.043V16Z" />
  </svg>
)
const Memo = memo(SvgRestaurant)
export default Memo
