import * as React from 'react'
import { memo } from 'react'
const SvgBeach = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="beach"
    viewBox="0 0 16.026 16"
  >
    <path d="M15.511 16.004 9.374 9.919l.516-.516 6.137 6.137ZM1.793 14.56A9.04 9.04 0 0 1 .001 9.119a8.82 8.82 0 0 1 1.792-5.441 5.2 5.2 0 0 0-.168 2.153 9.8 9.8 0 0 0 .554 2.218 13 13 0 0 0 1.019 2.1 16 16 0 0 0 1.225 1.779Zm3.12-3.12a10.3 10.3 0 0 1-1.637-2.45 10.7 10.7 0 0 1-.838-2.475 6.1 6.1 0 0 1-.077-2.14 2.57 2.57 0 0 1 .645-1.444 2.44 2.44 0 0 1 1.444-.619 6.3 6.3 0 0 1 2.127.116 10.6 10.6 0 0 1 2.45.864 10.6 10.6 0 0 1 2.411 1.625Zm7.014-7.014a18 18 0 0 0-1.728-1.2 13 13 0 0 0-2.05-1.031 10.4 10.4 0 0 0-2.179-.606 4.8 4.8 0 0 0-2.114.1A9.05 9.05 0 0 1 9.219 0a9 9 0 0 1 5.363 1.766Z" />
  </svg>
)
const Memo = memo(SvgBeach)
export default Memo
