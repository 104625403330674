import * as React from 'react'
import { memo } from 'react'
const SvgChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="chevron-right"
    viewBox="0 0 8 7.547"
  >
    <path d="M1.36 3.17A4.9 4.9 0 0 1 .002 0a20.7 20.7 0 0 0 8 3.774A19.7 19.7 0 0 0 0 7.547a5.15 5.15 0 0 1 1.509-3.17Z" />
  </svg>
)
const Memo = memo(SvgChevronRight)
export default Memo
