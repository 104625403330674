import * as React from 'react'
import { memo } from 'react'
const SvgPlay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="play"
    viewBox="0 -960 960 960"
  >
    <path d="M360-272.31v-415.38L686.15-480z" />
  </svg>
)
const Memo = memo(SvgPlay)
export default Memo
