import * as React from 'react'
import { memo } from 'react'
const SvgArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="arrow-right"
    viewBox="0 0 17.818 16"
  >
    <path d="M11.912 5.11a17 17 0 0 0 4.509 2.447l1.4.465-1.386.495a20.5 20.5 0 0 0-5 2.691 8.88 8.88 0 0 0-3.649 4.791l-.992-.221a9.86 9.86 0 0 1 4.035-5.387 21 21 0 0 1 3-1.846H0V7.531h13.84a19 19 0 0 1-2.54-1.609A13.35 13.35 0 0 1 6.816.378L7.76 0a12.34 12.34 0 0 0 4.151 5.11Z" />
  </svg>
)
const Memo = memo(SvgArrowRight)
export default Memo
