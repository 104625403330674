import * as React from 'react'
import { memo } from 'react'
const SvgBrand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="brand"
    viewBox="0 0 139.839 72"
  >
    <path d="M39.236 67.216a16.863 16.863 0 0 1 0-24.113 18.37 18.37 0 0 1 12.709-4.785 18.37 18.37 0 0 1 12.709 4.785 16.86 16.86 0 0 1 0 24.113A18.38 18.38 0 0 1 51.945 72a18.37 18.37 0 0 1-12.709-4.784m6.185-18.217a8.84 8.84 0 0 0-2.561 6.33 8.2 8.2 0 0 0 2.561 5.992 9.14 9.14 0 0 0 6.524 2.562 8.754 8.754 0 0 0 9.084-8.7 8.7 8.7 0 0 0-2.609-6.184 9.64 9.64 0 0 0-6.475-2.562 9.28 9.28 0 0 0-6.524 2.562m76.006 22.276V39.043h8.359v25.126h10.053v7.1Zm-23.628 0V39.043h18.314v7.1h-9.955v5.365h9.423v7.1h-9.423v5.561h9.955v7.1Zm-18.941 0V46.147H72v-7.1h22.081v7.1h-6.863v25.128Zm-58.418 0V58.228H8.36v13.047H0V39.043h8.36v12.033h12.08V39.043h8.41v32.232Zm11.551-39.041-2.368-18.557-7.781 18.557h-3.335l-7.441-18.557-2.754 18.557H0L5.461.001h8.263l6.524 17.2 6.911-17.2h8.359l4.833 32.233Zm79.616 0V.001h18.314v7.1h-9.954v5.368h9.422v7.1h-9.422v5.56h9.954v7.1Zm-13.133 0V19.862l-8.022 12.371H80.063l10.1-13.192a9.03 9.03 0 0 1-4.445-2.224 9.3 9.3 0 0 1-2.561-6.716 10.6 10.6 0 0 1 2.754-7.249C88.28.387 91.372 0 93.789 0h13.046v32.233Zm-5.606-24.5a3.8 3.8 0 0 0-1.111 2.707 4 4 0 0 0 1.063 2.706 6.28 6.28 0 0 0 4.06 1.3h1.595V6.476H96.88a5.68 5.68 0 0 0-4.012 1.256Zm-24.215 24.5-1.981-5.413h-11.7L52.8 32.233h-8.841L56.33.001h9.133l12.13 32.232ZM57.3 20.442h7.151l-3.626-10.438Z" />
  </svg>
)
const Memo = memo(SvgBrand)
export default Memo
