import * as React from 'react'
import { memo } from 'react'
const SvgExpand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="expand"
    viewBox="0 0 16 16"
  >
    <path d="M0 16V9.4h.982v5.614H6.6V16Zm15.018-9.4V.986H9.4V0H16v6.6Z" />
  </svg>
)
const Memo = memo(SvgExpand)
export default Memo
